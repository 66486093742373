<template>
  <div class="mobile-menu" id="menu-card">
    <img
      src="@/assets/icons/ClosePopup.svg"
      alt
      :class="locale === 'ara' ? 'mobile-menu-close-icon-rtl' : 'mobile-menu-close-icon'"
      @click="closeMenu(false)"
    />
    <div class="mobile-menu-card" v-for="(item, index) in menuList" :key="index">
      <div
        class="mobile-menu-card-content"
        v-if="
          subscriberid
            ? checkForBoldMenu(item) === true && item.isMenuEnabled
            : item.isMenuEnabled && !item.isRequiredLogin
        "
        @click="menuSelect(index)"
      >
        <div class="mobile-menu-card-content-item">
          <img :src="item.icon" alt="test" />
          <p>{{ locale === "ara" ? item.title.ar : item.title.default.toLowerCase() }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import moment from "moment";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    menu: {
      type: Array,
    },

    subscriptions: {
      type: Array | Object
    }
  },
  data() {
    return {
      menuList: [],
			mainMenu: [],
			moreMenu: [],
      subscriptionItemMenu: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "subscriptionList","appConfig", "profileid"]),
     
    locale() {
      return this.$i18n.locale;
    },
  },
  created() {
    // this.menuList = this.menu;
		// this.menuList = this.appConfig.moreMenuOptions;
     this.fetchMenuList();
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  methods: {
    closeMenu(state) {
      let response = {
        state: false,
      };

      response.change = state;
      this.$emit("close", response);
    },
    //checking if the age is matching to show bold category.
    checkForBoldMenu(item) {
      if (item.id === "BOLD") {
        if (localStorage.getItem("subscriberDetails")) {
          let details = JSON.parse(localStorage.getItem("subscriberDetails"));

          if (!details.data.dob) return false;

          let isKids = this.isKidsProfile(details.data.dob);

          if (isKids) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    },
    //Checking if it is kids mode or not.
    isKidsProfile(dob) {
      let d = new Date();
      let currentYear = d.getFullYear();
      let userYear = moment(dob)
        .format("YYYY-MM-DD")
        .split("-")[0];
      if (currentYear - userYear >= 0) {
        if (currentYear - userYear < 14) {
          return true;
        } else {
          return false;
        }
      }
    },
    fetchMenuList() {
			this.mainMenu = this.menu;
     	this.moreMenu = this.appConfig.moreMenuOptions;
			 this.menuList = this.mainMenu.concat(this.moreMenu)

    },
    //selecting menu category and routing to that component.
    menuSelect(index) {

      let currentLanguage = this.getCurrentLanguageUrlBase();

      let menuDetail = this.menuList[index];

      switch (menuDetail.id) {
        case "HOME":
          this.$router.push({ name: "Home", params: { menuDetail, lang: currentLanguage  } });
          break;
        case "SERIES":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: menuDetail.id, lang: currentLanguage  } });
          break;
        case "TVSHOWS":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: menuDetail.id , lang: currentLanguage } });
          break;
        case "MOVIES":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: menuDetail.id, lang: currentLanguage } });
          break;
        case "KIDS":
          // this.$router.push({ name: "kids", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: menuDetail.id, lang: currentLanguage  } });
          break;
        case "WATCHLIST":
          this.$router.push({ name: "Watchlist", params: { menuDetail, lang: currentLanguage  } });
          break;
        case "PLAN":
          let payload = { listType: "onlySubscribe" };
          this.$router.push({ name: "seeAllPlans", params: { menuDetail, lang: currentLanguage } });

          break;
        case "HOME-KIDS":
          this.$router.push({ name: "Home", params: { menuDetail, lang: currentLanguage  } });
          break;
      }

      this.closeMenu();
    },
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  mixins: [Utility]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./mobileMenu.scss"

</style>
